html {
    touch-action: none;
    user-select: none;
}

body {
    margin: 0 auto;
    color: #ffffff;
    font-family: 'Ubuntu', sans-serif;
    overflow: hidden;
    background-color: #000000;
    background-image: url('/images/hotel_view_images_hq/hotelview_default.png');
    background-repeat: no-repeat;
    background-position: center 0; 
}

canvas {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: -1;
}

button {
    cursor: pointer;
}