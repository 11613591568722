.catalogue {
    font-family: 'Volter';
    font-size: 9px;
    position: absolute;
    left: 20px;
    right: 0;
    top: 15px;
    height: 480px;
    width: 520px;
    z-index: 200;
    display: flex;
}

.catalogue .navigator {
    background: #418db0;
    border: 2px groove #69a4c0;
    border-radius: 5px;
    box-sizing: border-box;
    height: 405px;
    width: 167px;
    position: absolute;
    right: 0;
    top: 25px;
    z-index: 0;
    padding: 4px;
}

.catalogue button.close {
    position: absolute;
    top: 4px;
    right: 2px;
    width: 2em;
    padding: 0;
    color: #ffffff;
    background: inherit;
    border: none;
    font: inherit;
}

.catalogue .navigator h2 {
    font: inherit;
    margin: 0;
    text-align: center;
    font-weight: bold;
}

.catalogue .navigator hr {
    border: 1px solid #69a4c0;
    border-bottom: none;
    margin: 3px;
}

.catalogue .navigator .tab_container {
    width: 100%;
    height: 340px;
    background: #ffffff;
    border: 1px solid #000000;
    box-sizing: border-box;
    overflow-y: scroll;
}

.catalogue .navigator .tab_container button {
    font: inherit;
    width: 100%;
    height: 21px;
    background: #ffffff;
    border: none;
    border-bottom: 1px solid #000000;
    display: flex;
    padding: 0;
}

.catalogue .navigator .tab_container button.second_tab {
    background: #d2f0f3;
}

.catalogue .navigator .tab_container button.open {
    font-weight: bold;
}

.catalogue .navigator .tab_container button.selected, .catalogue .navigator .tab_container button:hover {
    background: #72d8e5;
}

.catalogue .navigator .tab_container button .icon {
    width: 23px;
    height: 20px;
    display: flex;
}

.catalogue .navigator .tab_container button.main_tab .icon {
    border-top: 2px solid rgba(255, 255, 255, 0.3);
    border-bottom: 1px solid rgba(0, 0, 0, 0.3);
    border-right: 1px solid rgba(0, 0, 0, 0.3);
    box-sizing: border-box;
}

.catalogue .navigator .tab_container button .icon img {
    object-fit: none;
    margin: auto;
}

.catalogue .navigator .tab_container button span {
    margin: auto 0;
    padding: 0 7px;
}

.catalogue .content {
    background: url('/images/catalogue/layout_bg.png') no-repeat;
    position: absolute;
    left: 0;
    width: 360px;
    height: 480px;
    z-index: 1;
    color: #000000;
}

.catalogue .content .handle {
    position: absolute;
    width: 100%;
    height: 80px;
}

.catalogue .wrapper {
    padding: 45px 20px;
    width: 315px;
}

.catalogue .wrapper .header_container {
    width: 100%;
    height: 60px;
    margin-bottom: 10px;
    display: flex;
}

.catalogue .wrapper .header_container img {
    margin: 0 auto;
    object-fit: none;
}

.catalogue .wrapper .description {
    margin-bottom: 15px;
    height: 70px;
    margin: 0 auto;
}

.catalogue .wrapper .frontpage_teaser {
    margin-bottom: 15px;
    height: 170px;
    background-repeat: no-repeat;
    overflow: hidden;
    margin: 0 auto;
    position: relative;
}

.catalogue .wrapper .frontpage_teaser img.border {
    position: absolute;
    z-index: 3;
    object-fit: none;
}

.catalogue .wrapper .frontpage_teaser img.top_story {
    position: absolute;
    z-index: 2;
    top: 12px;
    left: 12px;
    width: 280px;
    height: 145px;
    object-fit: none;
}

.catalogue .wrapper .frontpage_teaser h2 {
    position: absolute;
    font: inherit;
    top: 70px;
    left: 35px;
    z-index: 4;
    color: #faf8cc;
}

.catalogue .wrapper .frontpage_teaser p {
    position: absolute;
    font: inherit;
    top: 90px;
    left: 35px;
    z-index: 4;
    color: #cecece;
}

.catalogue .wrapper .frontpage_content {
    width: 100%;
    height: 125px;
    background-color: #cbcbcb;
    padding: 15px;
    box-sizing: border-box;
    border-radius: 5px;
}

.catalogue .wrapper .frontpage_content h2 {
    font: inherit;
    font-weight: bold;
}

.catalogue .wrapper .frontpage_vouchers {
    margin-top: 5px;
    background-color: #6d9dcd;
    width: 100%;
    height: 35px;
    display: flex;
    padding: 5px;
    box-sizing: border-box;
    border-radius: 5px;
}

.catalogue .wrapper .frontpage_vouchers span {
    margin: auto;
}

.catalogue .wrapper .frontpage_vouchers input {
    height: 20px;
    width: 130px;
    margin: auto 15px;
    font: inherit;
    padding: 2px 5px;
    border-radius: 5px;
    border: 1px solid #777777;
    box-sizing: border-box;
}

.catalogue .wrapper .frontpage_vouchers button {
    margin: auto;
    font: inherit;
    height: 20px;
    border: 1px solid #000000;
    background: #ffffff;
    padding: 0 7px;
    border-radius: 3px;
}

.catalogue .wrapper .frontpage_vouchers button:hover {
    background: #efefef;
}

.catalogue .wrapper .second_row {
    display: flex;
}

.catalogue .wrapper .second_row .grid_container {
    width: 50%;
    height: 250px;
}

.catalogue .wrapper .second_row .grid_container .title {
    font-weight: bold;
}

.catalogue .wrapper .second_row .grid_container .grid {
    width: 100%;
    height: 240px;
    display: flex;
    flex-wrap: wrap;
    align-content: flex-start;
    overflow-y: scroll;
    overflow-x: hidden;
}

.catalogue .wrapper .second_row .grid_container .grid button {
    width: 38px;
    height: 38px;
    border-radius: 5px;
    box-sizing: border-box;
    border: none;
    margin: 2px;
    background-color: #ffffff;
    position: relative;
    padding: 1px;
    font: inherit;
    font-size: 0.55em;
    overflow: hidden;
}

.catalogue .wrapper .second_row .grid_container .grid button:hover, .catalogue .wrapper .second_row .grid_container .grid button.selected {
    border: 1px solid #9d9d9d;
    border-radius: 10px;
}

.catalogue .wrapper .second_row .item_description {
    width: 50%;
    height: 250px;
}

.catalogue .wrapper .second_row .item_description .image_container {
    height: 165px;
    display: flex;
    overflow: hidden;
}

.catalogue .wrapper .second_row .description_container {
    height: 40px;
    overflow: hidden;
    padding: 1px;
}

.catalogue .wrapper .second_row .button_container {
    background-color: #ffc900;
    padding: 10px;
    border-radius: 10px;
    display: flex;
}

.catalogue .wrapper .second_row .button_container span {
    margin: auto;
}

.catalogue .wrapper .second_row .button_container button {
    margin: auto;
    font: inherit;
    height: 20px;
    border: 1px solid #000000;
    background: #ffffff;
    padding: 0 7px;
    border-radius: 3px;
}

.catalogue .wrapper .second_row .button_container button:hover {
    background: #efefef;
}

.catalogue .wrapper .second_row .item_description .image_container img {
    object-fit: none;
    margin: auto;
}

.catalogue .wrapper .second_row .item_description .description_container h2 {
    font: inherit;
    font-weight: bold;
    margin: 0;
}

.catalogue .wrapper .second_row .item_description .description_container p {
    margin: 0;
}