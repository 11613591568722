.avatar_editor {
    font-family: 'Volter';
    font-size: 9px;
    position: absolute;
    left: 20px;
    right: 0;
    top: 15px;
    height: 410px;
    width: 380px;
    background: #3d3d3d;
    border: 1px solid #282722;
    padding: 5px 15px;
    box-sizing: border-box;
    border-radius: 5px;
    text-align: center;
    z-index: 200;
}

.avatar_editor button.close {
    position: absolute;
    top: 7px;
    right: 2px;
    width: 2em;
    padding: 0;
    color: #ffffff;
    background: inherit;
    border: none;
    font: inherit;
}

.avatar_editor h2 {
    font: inherit;
    font-weight: bold;
    margin: 0;
}

.avatar_editor hr {
    border: 1px solid #2f2f2f;
    border-bottom: none;
    margin: 3px;
}

.avatar_editor .main_tab_container {
    display: flex;
    padding: 0 10px;
    width: 100%;
}

.avatar_editor .main_tab_container button {
    background-color: #4c4c4c;
    border: none;
    width: 52px;
    height: 41px;
    box-sizing: border-box;
    border: 1px solid #202020;
    border-radius: 5px 5px 0 0;
}

.avatar_editor .main_tab_container button.selected, .avatar_editor .main_tab_container button:hover {
    background-color: #656565;
    border-bottom: 1px solid #656565;
}

.avatar_editor .parts_container {
    background-color: #656565;
    border-radius: 5px;
    height: 300px;
    padding: 2px 8px;
    box-sizing: border-box;
}

.avatar_editor .parts_container .first_row {
    display: flex;
    margin-top: 2px;
}

.avatar_editor .second_tab_container {
    display: flex;
}

.avatar_editor .second_tab_container button {
    display: flex;
    background: inherit;
    border: none;
    color: #ffffff;
    font: inherit;
    margin-right: 5px;
    border-radius: 5px;
    height: 37px;
    width: 60px;
}

.avatar_editor .second_tab_container button.gender {
    width: 90px;
}

.avatar_editor .second_tab_container button:hover, .avatar_editor .second_tab_container button.selected {
    background: #757575;
}

.avatar_editor .second_tab_container button img, .avatar_editor .second_tab_container button span {
    margin: auto;
}

.avatar_editor .parts_grid {
    background-color: #f0f0f0;
    width: 255px;
    height: 118px;
    border-radius: 5px;
    box-sizing: border-box;
    display: flex;
    flex-wrap: wrap;
    align-content: flex-start;
    padding: 5px;
    overflow-y: scroll;
    overflow-x: hidden;
}

.avatar_editor .parts_grid button {
    width: 50px;
    height: 50px;
    border-radius: 5px;
    box-sizing: border-box;
    border: none;
    margin: 2px;
    background-color: #ffffff;
    position: relative;
}

.avatar_editor .parts_grid button img.generic {
    object-fit: none;
    object-position: 65% 25%;
    width: 45px;
    height: 45px;
}

.avatar_editor .parts_grid button img.head {
    object-fit: none;
    object-position: 65% 17%;
    width: 45px;
    height: 45px;
}

.avatar_editor .parts_grid button img.torso {
    object-fit: none;
    object-position: 65% 65%;
    width: 45px;
    height: 45px;
}

.avatar_editor .parts_grid button img.legs {
    object-fit: none;
    object-position: 65% 100%;
    width: 45px;
    height: 45px;
}

.avatar_editor .parts_grid button.hc::after {
    display: block;
    content: ' ';
    width: 9px;
    height: 7px;
    background: url('/images/avatar_editor/hc.png') no-repeat 0 0;
    position: absolute;
    right: 2px;
    bottom: 2px;
}

.avatar_editor .parts_grid button.selected, .avatar_editor .parts_grid button:hover {
    background-color: #cbcbcb;
}

.avatar_editor .platform {
    background: url('/images/avatar_editor/platform.png') 3px 58px no-repeat;
    height: 118px;
    width: 77px;
    position: relative;
}

.avatar_editor .platform img {
    position: absolute;
    left: 11px;
    bottom: 24px;
}

.avatar_editor .palette_container {
    width: 332px;
    box-sizing: border-box;
    display: flex;
    border-radius: 5px;
}

.avatar_editor .colors_container {
    background-color: #f0f0f0;
    margin: 9px auto;
    height: 118px;
    box-sizing: border-box;
    padding: 20px 7px;
    display: flex;
    flex-wrap: wrap;
    align-content: flex-start;
    overflow-y: scroll;
    overflow-x: hidden;
}

.avatar_editor .colors_container button {
    width: 25px;
    height: 21px;
    border-radius: 3px;
    box-sizing: border-box;
    border: 1px solid #000000;
    margin: 1px;
    background-color: #f6f6f6;
    padding: 2px;
    position: relative;
}

.avatar_editor .colors_container button.hc::after {
    display: block;
    content: ' ';
    width: 9px;
    height: 7px;
    background: url('/images/avatar_editor/hc.png') no-repeat 0 0;
    position: absolute;
    right: 2px;
    bottom: 2px;
}

.avatar_editor .colors_container button .palette {
    width: 100%;
    height: 100%;
    background-color: #ddaf83;
    border-top: 1px solid rgba(255, 255, 255, 0.4);
    border-bottom: 1px solid rgba(0, 0, 0, 0.2);
    box-sizing: border-box;
}

.avatar_editor .colors_container button.selected, .avatar_editor .colors_container button:hover {
    padding: 4px;
}

.avatar_editor .button_container {
    display: flex;
    margin-top: 10px;
}

.avatar_editor .button_container button {
    margin: auto;
    min-width: 35px;
    height: 25px;
    background-color: #3d3d3d;
    border: 1px solid #eeeeee;
    border-radius: 4px;
    box-sizing: border-box;
    display: flex;
    color: inherit;
    font: inherit;
    color: #eeeeee;
    padding: 0 10px 2px 10px;
}

.avatar_editor .button_container button:hover {
    background-color: #4c4c4c;
}