.logo {
    margin: auto;
}

.logo a {
    text-decoration: none;
    color: inherit;
}

.logo h1 {
    display: inline;
    margin-left: 0.1em;
    font-family: 'Poiret One', sans-serif;
    font-weight: inherit;
    font-size: 3em;
    text-transform: lowercase;
    text-shadow: 0 0 2px #000000;
}