.catalogue_confirm_purchase {
    position: absolute;
    left: 20px;
    right: 0;
    top: 15px;
    height: 200px;
    width: 280px;
    background: #418db0;
    border: 2px groove #69a4c0;
    box-sizing: border-box;
    border-radius: 5px;
    text-align: center;
    z-index: 200;
    padding: 4px;
    font-family: 'Volter';
    font-size: 9px;
}

.catalogue_confirm_purchase button.close {
    position: absolute;
    top: 4px;
    right: 2px;
    width: 2em;
    padding: 0;
    color: #ffffff;
    background: inherit;
    border: none;
    font: inherit;
}

.catalogue_confirm_purchase h2 {
    font: inherit;
    margin: 0;
    text-align: center;
    font-weight: bold;
}

.catalogue_confirm_purchase hr {
    border: 1px solid #69a4c0;
    border-bottom: none;
    margin: 3px;
}

.catalogue_confirm_purchase .wrapper {
    width: 100%;
    height: 90%;
    background: #ffffff;
    border: 1px solid #567483;
    border-radius: 5px;
    box-sizing: border-box;
    color: #000000;
    padding: 10px;
    position: relative;
}

.catalogue_confirm_purchase .first_row {
    height: 40%;
    display: flex;
}

.catalogue_confirm_purchase .first_row button {
    margin: auto;
    width: 42px;
    height: 42px;
    border-radius: 5px;
    box-sizing: border-box;
    border: 1px solid #a0a0a0;
    margin: 0;
    background-color: #f0f0f0;
    position: relative;
    padding: 1px;
    font: inherit;
    font-size: 0.55em;
}

.catalogue_confirm_purchase .first_row h2 {
    margin: auto;
}

.catalogue_confirm_purchase .second_row {
    height: 30%;
}

.catalogue_confirm_purchase .third_row {
    height: 30%;
    display: flex;
}

.catalogue_confirm_purchase .third_row button {
    width: 30%;
    margin: auto;
    font: inherit;
    height: 25px;
    border: 1px solid #000000;
    background: #ffffff;
    padding: 0 7px;
    border-radius: 3px;
}

.catalogue_confirm_purchase .third_row button:hover {
    background: #efefef;
}
