.inventory {
    font-family: 'Volter';
    font-size: 9px;
    position: absolute;
    left: 20px;
    right: 0;
    top: 15px;
    height: 300px;
    width: 390px;
    background: #418db0;
    border: 2px groove #69a4c0;
    box-sizing: border-box;
    border-radius: 5px;
    text-align: center;
    z-index: 200;
    padding: 4px;
}

.inventory button.close {
    position: absolute;
    top: 3px;
    right: 2px;
    width: 2em;
    padding: 0;
    color: #ffffff;
    background: inherit;
    border: none;
    font: inherit;
}

.inventory h2 {
    font: inherit;
    font-weight: bold;
    margin: 0;
}

.inventory hr {
    border: 1px solid #69a4c0;
    border-bottom: none;
    margin: 3px;
}

.inventory .main_tab_container {
    display: flex;
    padding: 0 10px;
}

.inventory .main_tab_container button {
    background-color: #9fbdcc;
    width: 100%;
    height: 20px;
    box-sizing: border-box;
    border: 1px solid #3d5059;
    border-radius: 5px 5px 0 0;
    font: inherit;
    color: #000000;
}

.inventory .main_tab_container button:hover, .inventory .main_tab_container button.selected {
    background: #ffffff;
    border-bottom: 1px solid #ffffff;
}

.inventory .items_container {
    background-color: #ffffff;
    border-radius: 5px;
    height: 250px;
    padding: 2px 8px;
    box-sizing: border-box;
    border: 1px solid #567483;
    border-top: none;
}

.inventory .second_tab_container {
    display: flex;
    border: 1px solid #000000;
    margin: 3px 0;
    border-radius: 3px;
    border-right: none;
}

.inventory .second_tab_container button {
    background: inherit;
    border: none;
    color: #000000;
    font: inherit;
    border-right: 1px solid #000000;
    height: 22px;
    width: 100%;
    outline: none;
}

.inventory .second_tab_container button:hover, .inventory .second_tab_container button.selected {
    background: #989898;
}

.inventory .grid_container {
    display: flex;
    height: 205px;
    width: 100%;
}

.inventory .grid_container .grid {
    width: 205px;
    display: flex;
    flex-wrap: wrap;
    align-content: flex-start;
    overflow-y: scroll;
    overflow-x: hidden;
}

.inventory .grid_container .grid button {
    width: 42px;
    height: 42px;
    border-radius: 5px;
    box-sizing: border-box;
    border: none;
    margin: 2px;
    background-color: #cbcbcb;
    position: relative;
    padding: 1px;
    font: inherit;
    overflow: hidden;
}

.inventory .grid_container .grid button.selected, .inventory .grid_container .grid button:hover {
    background-color: #9bca64;
}

.inventory .grid_container .grid button span {
    position: absolute;
    top: 1px;
    right: 1px;
    background: #ffffff;
    border: 1px solid #2f6982;
    color: #2f6982;
    padding: 0 2px;
}

.inventory .grid_container .item_preview {
    width: 150px;
    position: relative;
}

.inventory .grid_container .item_preview .image_container {
    width: 100%;
    height: 100px;
    overflow: hidden;
}

.inventory .grid_container .item_preview .item_data {
    color: #000000;
    text-align: left;
    padding: 5px 10px;
}

.inventory .grid_container .item_preview .item_data h2 {
    font-weight: bold;
}

.inventory .grid_container .item_preview .item_data p {
    margin: 0;
}

.inventory .grid_container .item_preview .item_data button {
    position: absolute;
    bottom: 0px;
    background: inherit;
    border: 1px solid #000000;
    padding: 5px 10px;
    font: inherit;
    border-radius: 3px;
}

.inventory .grid_container .item_preview .item_data button:hover {
    background-color: #cbcbcb;
}